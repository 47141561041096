import React, { useEffect } from 'react';
import { Suspense, useContext } from 'react';
import { Switch, Route, Redirect, useParams, useRouteMatch, useLocation } from 'react-router-dom';

import { LANG_EN } from '../../util/language.js';
import { CHBLinkEN, CHBLinkFR } from '../../util/settings';

import {
    FINANCES_PATH, ABUSE_AND_NEGLECT_PATH, PROGRAM_PATH, SEARCH_PATH, CATEGORY_PATH, COMPLEX_PATH,
    SIMPLE_PATH, CALCULATOR_PATH, HOME_FIRST_PATH, SCREENING_PATH, CHB_REGISTRATION_PATH, DISABILITY_PATH,
    HOUSING_PATH, NAVIGATOR_PATH, PAGE_PATH, FAMILIES_AND_YOUTH_PATH, YOUR_HEALTH_PATH
} from '../../globals/constants';
import { ErrorContext, LanguageContext } from '../../globals/Context.js';
import { Helmet } from 'react-helmet-async';
import LoadingSpinner from '../LoadingSpinner.js';

const LandingPage = React.lazy((() => import ('../landingPage')))
const NoPage = React.lazy((() => import ('../noPage')))
const SearchPage = React.lazy((() => import ('../SearchPage')))
const CategoryPage = React.lazy((() => import ('../categoryPage')))
const ProgramPage = React.lazy((() => import ('../programPage')))
const ComplexProgramPage = React.lazy((() => import ('../complexProgramPage')))
const SimpleProgramPage = React.lazy((() => import ("../simpleProgramPage")))
const RegistrationPage = React.lazy((() => import ("../registrationPage")))
const Calculator = React.lazy((() => import ('../calculator')))
const ScopeAbuseAndNeglect = React.lazy((() => import ('../scopeAbuseAndNeglect')))
const ScopeDisability = React.lazy((() => import ('../scopeDisability')))
const ScopeFamiliesAndYouth = React.lazy((() => import ('../scopeFamiliesAndYouth')))
const ScopeFinances = React.lazy((() => import ('../scopeFinances')))
const ScopeHousing = React.lazy((() => import ('../scopeHousing')))
const ScopeSeniorNavigator = React.lazy((() => import ('../scopeSeniorNavigator')))
const ScopeYourHealth = React.lazy((() => import ('../scopeYourHealth')))

const SubRouter = (props) => {
    const { language } = useParams()
    const { path } = useRouteMatch()
    const { pathname } = useLocation();
    const { languageCode, languageStrings, setCurrentLanguage } = useContext(LanguageContext);
    const { isError } = useContext(ErrorContext);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    if(language !== languageCode){
      return <LoadingSpinner />
    }

    // Pathname check prevents infinite redirect loops
    if(isError && !pathname?.includes("error")) {
        return <Redirect push to={`/${language}/error`}/>
    }

    return (
        <Suspense fallback={<div><div id="loader"></div></div>}>
            <Helmet>
                <title>{languageStrings.WEBSITE_TITLE}</title>
            </Helmet>
            <Switch>
                {/* Homepage */}
                <Route path={`${path}`}                                     component={LandingPage} exact />
                {/* Subpages (SD's internal term: Landing Pages) */}
                <Route path={`${path}${NAVIGATOR_PATH}`}                    component={ScopeSeniorNavigator} exact/>
                <Route path={`${path}${DISABILITY_PATH}`}                   component={ScopeDisability} exact />
                <Route path={`${path}${HOUSING_PATH}`}                      component={ScopeHousing} exact />
                <Route path={`${path}${FINANCES_PATH}`}                     component={ScopeFinances} exact />
                <Route path={`${path}${ABUSE_AND_NEGLECT_PATH}`}            component={ScopeAbuseAndNeglect} exact />
                <Route path={`${path}${FAMILIES_AND_YOUTH_PATH}`}           component={ScopeFamiliesAndYouth} exact />
                <Route path={`${path}${YOUR_HEALTH_PATH}`}                  component={ScopeYourHealth} exact />
                {/* Programs, searches, categories, etc */}
                <Route path={`${path}${CALCULATOR_PATH}`}                   component={Calculator} exact />
                <Route path={`${path}${SEARCH_PATH}/`}                      component={SearchPage}/>
                <Route path={`${path}${CATEGORY_PATH}/:categoryTitle`}      component={CategoryPage} />
                <Route path={`${path}${PROGRAM_PATH}/:title`}               component={ProgramPage} />
                <Route path={`${path}${COMPLEX_PATH}/:title`}               component={ComplexProgramPage} exact />
                <Route path={`${path}${SIMPLE_PATH}/:title`}                component={SimpleProgramPage} />
                <Route path={`${path}${HOME_FIRST_PATH}`}                   component={RegistrationPage} />
                <Route path={`${path}${PAGE_PATH}/:title`}                  component={SimpleProgramPage}/>
                {/* Canada Housing Benefit */}
                <Route path={`${path}${SCREENING_PATH}`} component={() => {
                    const newPath = language === LANG_EN ?
                        `/${language}/simple_page/canada-new-brunswick-housing-benefit-application-form` :
                        `/${language}/simple_page/formulaire-lallocation-canada-nouveau-brunswick-pour-le-logement`
                    return <Redirect to={newPath}/>
                }}/>
                <Route path={`${path}${CHB_REGISTRATION_PATH}`} component={() => {
                    if (language === LANG_EN) {
                        window.location.href = CHBLinkEN;
                    } else {
                        window.location.href = CHBLinkFR;
                    }
                    return null;
                }}/>
                {/* Error */}
                <Route path={`${path}error`}                                component={NoPage} exact/>
            </Switch>
        </Suspense>
    )
};

export default SubRouter