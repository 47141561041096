import * as settings from '../util/settings';
const BASE_URL = settings.BaseUrl;
const TIMEOUT = settings.Timeout;
const USER = settings.User;
const PASSWORD = settings.Password;


//TODO add calls for getting Complex Programs and details about it similar to the ones below for programs.


// timeout function.
function timeout(ms, promise) {
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        reject(new Error("timeout"))
      }, ms)
      promise.then(resolve, reject)
    })
  }
 

  function getMenu() {
    var headerData = {
        "Accept": "application/vnd.api+json",
        "Content-type": "application/vnd.api+json",
        "Authorization": "Basic {base64_encode(user:user)}"
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let finalUrl = BASE_URL + 'm  ';

    
    return fetch(encodeURI(finalUrl), data).then((response) => response.json());
}

function getCategories(language, responseData =null, url =null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null) {
      finalUrl = BASE_URL + langStr + 'jsonapi/taxonomy_term/program_categories/?include=field_category_image,field_pagescope,field_image_disability,field_image_seniors';
    }
    return fetch(finalUrl, data)
        .then((response) => response.json())
        .then((response2) => {
            if (responseData === null) {
                responseData = response2;
            } else {
                responseData.data.push(...response2.data);
                responseData.included.push(...response2.included);
            }
            if (response2.links.hasOwnProperty("next")) {
                // let nextUrl = fixUrl(response2.links.next.href);
                let nextUrl = checkHttps(response2.links.next.href);
                return getCategories(language, responseData, nextUrl);
            } else {
                return responseData;
            }
        }).catch(function(error) { throw Error; });

}

function getSearchKeywords(language, responseData = null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null) {
        finalUrl = encodeURI(BASE_URL + langStr + 'jsonapi/taxonomy_term/keywords/');
    }
    return fetch(finalUrl, data)
        .then((response) => response.json())
        .then((response2) => {
            if (responseData === null) {
                responseData = response2;
            } else {
                responseData.data.push(...response2.data);
            }
            if (response2.links !== undefined && response2.links.next !== undefined && response2.links.next.href !== null) {
                // let nextUrl = fixUrl(response2.links.next.href);
                let nextUrl = checkHttps(response2.links.next.href);
                getSearchKeywords(language, responseData, nextUrl);
            } else {
                return responseData;
            }
            return responseData;
        }).catch(function(error) { throw Error; });
}

function getCategoryById(id) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let finalUrl = BASE_URL + 'jsonapi/taxonomy_term/program_categories/' + id;
    //return fetch(encodeURI(finalUrl), data).then((response) => response.json());
    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function(response) {
        return  response.json();
      }).catch(function(error) {
       throw Error;
      });
}

function searchByCategory(cat, language, responseData= null, url= null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    
    let langStr = getLangStr(language);
    let finalUrl = url;
    if(url===null){
      finalUrl = BASE_URL + langStr + 'jsonapi/node/program/?filter[field_program_categories.name][value]=' + cat + '&include=field_program_image,field_program_categories&filter[langcode][value]=' + language;
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return searchByCategory(cat, language, responseData, nextUrl);
        } else {
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

function getProgramsByCategoryId (cat, language, responseData=null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null){
        finalUrl = BASE_URL + langStr + 'jsonapi/node/program/?filter[field_program_categories.id][value]=' + cat + '&include=field_pagescope,field_program_image,field_program_categories&filter[langcode][value]=' + language;
    } 
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getProgramsByCategoryId(cat, language, responseData, nextUrl);
        } else {
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

function getProgramsByCategories(categoryIds, language, searchableFilter = false, responseData=null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null){
      let urlStart = BASE_URL + langStr + 'jsonapi/node/program/?filter[group-name][condition][path]=field_program_categories.id&filter[group-name][condition][operator]=IN';
      finalUrl = urlStart;
      categoryIds.forEach((key) => {
          finalUrl = finalUrl + '&filter[group-name][condition][value][]=' + key;
      });
      let urlEnd = '&include=field_pagescope,field_program_image,field_program_categories&filter[langcode][value]=' + language;
      finalUrl = finalUrl + urlEnd;
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getProgramsByCategories(categoryIds, language, searchableFilter, responseData, nextUrl);
        } else {
            responseData.data = searchableFilter ? responseData.data.filter(item => item.attributes.field_searchable) : responseData.data;
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

function getProgramById(id, language) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = BASE_URL + langStr + 'jsonapi/node/program/' + id + '?include=field_pagescope,field_program_categories,field_program_contact_collection,field_program_documents,field_program_image,field_program_needs_collection,field_program_questions,field_program_requirements,field_search_keywords,field_program_owner&filter[langcode][value]=' + language;
    //return fetch(encodeURI(finalUrl), data).then((response) => response.json());
 
    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function(response) {
        return  response.json();
      }).catch(function(error) {
        throw Error;
      });
}

async function getProgramPathById(id, language) {
    let langStr = getLangStr(language);
    const finalUrl = new URL(`${BASE_URL}${langStr}jsonapi/node/program/${id}`);
    const params = new URLSearchParams({
        "fields[node--program]": "path",
    });
    finalUrl.search = params.toString();
    const response = await fetch(finalUrl, {
        method: 'GET',
        headers: new Headers()
    });
    try {
        const json = await response.json();
        return json.data.attributes.path.alias;
    } catch {
        console.error("Caught error while fetching program path by id. Returning empty string.");
        return '';
    }
}

function getPrograms(language, searchableFilter = false, responseData = null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if(url === null){
      finalUrl = BASE_URL + langStr + 'jsonapi/node/program/?include=field_pagescope,field_program_categories,field_program_contact_collection,field_program_documents,field_program_image,field_program_needs_collection,field_program_questions,field_program_requirements,field_search_keywords,field_program_owner&filter[langcode][value]=' + language;
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getPrograms(language, searchableFilter, responseData, nextUrl);
        } else {
            responseData.data = searchableFilter ? responseData.data.filter(item => item.attributes.field_searchable) : responseData.data;
            return responseData;
        }
    }).catch(function(error) { 
        throw Error; });
}


function getProgramByTitle(title, language) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = BASE_URL +  langStr + 'jsonapi/node/program/?filter[title][value]=' + title + '&include=field_pagescope,field_program_categories,field_program_contact_collection,field_program_documents,field_program_image,field_program_needs_collection,field_program_questions,field_program_requirements,field_program_owner&filter[langcode][value]=' + language;
    //return fetch(encodeURI(finalUrl), data).then((response) => response.json());

    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function(response) {
        return  response.json();
      }).catch(function(error) {
        throw Error;
      });
}

function getDocuments(language, responseData=null, url= null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null){
      finalUrl = BASE_URL +  langStr + 'jsonapi/node/document/?include=field_document_file';
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getDocuments(language, responseData, nextUrl);
        } else {
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

function getContacts(language, responseData =null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null){
      finalUrl = BASE_URL +  langStr + 'jsonapi/node/contact/?filter[langcode][value]=' + language;
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getContacts(language, responseData, nextUrl);
        } else {
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

function getContactByCollection(title, language) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = BASE_URL +  langStr + 'jsonapi/node/contact/?filter[field_contact_collections.name][value]='+ title + '&filter[langcode][value]=' + language;
    //return fetch(encodeURI(finalUrl), data).then((response) => response.json());
    
    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function(response) {
        return  response.json();
      }).catch(function(error) {
        throw Error;
      });
}

// function fixUrl(url){
//     let fixedUrl = url.replace(/.*:\/\/[\x00-\x2E, \x30-\xFF]*\//, BASE_URL);
//     return fixedUrl;
// }

export function checkHttps(url){
    let fixedUrl = url;
    if(fixedUrl.includes("http://") && !(fixedUrl.includes("http://localhost:"))){
        fixedUrl = fixedUrl.replace("http:", "https:");
    }
    return fixedUrl;
}


// FIXME How to import the constant here..
function getLangStr(language) {
    let langStr = ""
    if (language === "fr") {
        langStr = language + "/";
    } 
    return langStr;
}

function getSimplePrograms(language, searchableFilter = false, responseData = null, url = null, ) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if(url === null){
      finalUrl = BASE_URL + langStr + 'jsonapi/node/simple_page/?include=field_pagescope,node_type, revision_uid, uid, menu_link, field_add_an_image_large_800_x_4, field_program_documents, field_program_categories, field_program_contact_collection, field_program_owner, field_search_keywords&filter[langcode][value]=' + language;
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getPrograms(language, searchableFilter, responseData, nextUrl);
        } else {
            responseData.data = searchableFilter ? responseData.data.filter(item => item.attributes.field_searchable) : responseData.data;
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

function getSimpleProgramById(id, language) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = BASE_URL + langStr + 'jsonapi/node/simple_page/' + id + '?include=field_pagescope,node_type, revision_uid, uid, menu_link, field_add_an_image_large_800_x_4, field_program_documents, field_program_categories, field_program_contact_collection, field_program_owner, field_search_keywords&filter[langcode][value]=' + language;
    //return fetch(encodeURI(finalUrl), data).then((response) => response.json());
 
    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function(response) {
        return  response.json();
      }).catch(function(error) {
        throw Error;
      });
}

function getSimpleProgramsByCategories(categoryIds, language, searchableFilter = false, responseData=null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null){
      let urlStart = BASE_URL + langStr + 'jsonapi/node/simple_page/?filter[group-name][condition][path]=field_program_categories.id&filter[group-name][condition][operator]=IN';
      finalUrl = urlStart;
      categoryIds.forEach((key) => {
          finalUrl = finalUrl + '&filter[group-name][condition][value][]=' + key;
      });
      let urlEnd = '&include=field_pagescope,field_add_an_image_large_800_x_4,field_program_categories&filter[langcode][value]=' + language;
      finalUrl = finalUrl + urlEnd;
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getSimpleProgramsByCategories(categoryIds, language, searchableFilter, responseData, nextUrl);
        } else {
            responseData.data = searchableFilter ? responseData.data.filter(item => item.attributes.field_searchable) : responseData.data;
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

//! COMPLEX PAGE FUNCTIONS: 
//* No "field_program_image" attribute

function getComplexProgramById(id, language) {
    var headerData = {};
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = BASE_URL + langStr + "jsonapi/node/complex_page/" + id + "?include=field_pagescope,field_program_categories,field_program_contact_collection,field_program_documents,field_add_an_image_large_800_x_4,field_program_questions,field_search_keywords,field_program_owner&filter[langcode][value]=" + language;

    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function (response) {
        return response.json();
    }).catch(function (error) {
        throw Error;
    });
} 

function getComplexPrograms(language, searchableFilter = false, responseData = null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null) {
        finalUrl = BASE_URL + langStr + 'jsonapi/node/complex_page/?include=field_pagescope,field_program_categories,field_program_contact_collection,field_program_documents,field_program_questions,field_add_an_image_large_800_x_4,field_search_keywords,field_program_owner&filter[langcode][value]=' + language;
    }
    return fetch(finalUrl, data)
        .then((response) => response.json())
        .then((response2) => {
            if (responseData === null) {
                responseData = response2;
            } else {
                responseData.data.push(...response2.data);
                responseData.included.push(...response2.included);
            }
            if (response2.links.hasOwnProperty("next")) {
                // let nextUrl = fixUrl(response2.links.next.href);
                let nextUrl = checkHttps(response2.links.next.href);
                return getPrograms(language, searchableFilter, responseData, nextUrl);
            } else {
                responseData.data = searchableFilter ? responseData.data.filter(item => item.attributes.field_searchable) : responseData.data;
                return responseData;
            }
        }).catch(function (error) { 
            throw Error; 
        });
}

function getComplexHowToApplyByProgram(id, language) {
    var headerData = {};
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = BASE_URL + langStr + "jsonapi/node/complex_program_how_do_i_apply/?filter[field_related_complex_program.id][value]="+id;

    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function (response) {
        return response.json();
    }).catch(function (error) {
        throw Error;
    });
} 

function getComplexProgramsByCategories(categoryIds, language, searchableFilter = false, responseData=null, url = null) {
    var headerData = {
    };
    var headers = new Headers(headerData);
    var data = { method: 'GET', headers: headers }
    let langStr = getLangStr(language);
    let finalUrl = url;
    if (url === null){
      let urlStart = BASE_URL + langStr + 'jsonapi/node/complex_page/?filter[group-name][condition][path]=field_program_categories.id&filter[group-name][condition][operator]=IN';
      finalUrl = urlStart;
      categoryIds.forEach((key) => {
          finalUrl = finalUrl + '&filter[group-name][condition][value][]=' + key;
      });
      let urlEnd = '&include=field_pagescope,field_add_an_image_large_800_x_4,field_program_categories&filter[langcode][value]=' + language;
      finalUrl = finalUrl + urlEnd;
    }
    return fetch(finalUrl, data)
    .then((response) => response.json())
    .then((response2) => {
        if (responseData === null) {
            responseData = response2;
        } else {
            responseData.data.push(...response2.data);
            responseData.included.push(...response2.included);
        }
        if (response2.links.hasOwnProperty("next")) {
            // let nextUrl = fixUrl(response2.links.next.href);
            let nextUrl = checkHttps(response2.links.next.href);
            return getComplexProgramsByCategories(categoryIds, language, searchableFilter, responseData, nextUrl);
        } else {
            responseData.data = searchableFilter ? responseData.data.filter(item => item.attributes.field_searchable) : responseData.data;
            return responseData;
        }
    }).catch(function(error) { throw Error; });
}

function postHomeFirstApplication(bodyData) {
    const userPass = 'Basic ' + btoa(USER + ":" + PASSWORD);
    var headerData = {
        "Authorization": userPass,
        "Content-Type":"application/json"
    };
    
    var headers = new Headers(headerData);
    var data = { method: 'POST', headers: headers, body: JSON.stringify(bodyData) }
    let finalUrl = BASE_URL + "webform_rest/submit";
    // let finalUrl="/webform_rest/submit";


    return timeout(TIMEOUT, fetch(encodeURI(finalUrl), data)).then(function (response) {
        return {data:response.json(), status:response.status};
    }).catch(function (error) {
        throw Error;
    });
} 

async function getButtons(programId, language){
    // TODO: refactor out
    const langStr = getLangStr(language)

    const fetchURL = new URL(`${BASE_URL}${langStr}/jsonapi/node/application_button`)
    fetchURL.searchParams.set("filter[field_programs.id][value]", programId)
    // TODO: dont think we need langcode since the api endpoints do it automatically
    fetchURL.searchParams.set("filter[langcode][value]", language)

    const response = await fetch(fetchURL)
    const json = await response.json()
    return response.ok ? json.data : []
}


export {
    getCategories,
    getCategoryById,
    searchByCategory,
    getProgramsByCategoryId,
    getProgramsByCategories,
    getProgramById,
    getProgramPathById,
    getProgramByTitle,
    getPrograms,
    getContactByCollection,
    getContacts,
    getMenu,
    getSearchKeywords,
    BASE_URL,
    getDocuments,
    getComplexProgramById,
    getComplexPrograms,
    getComplexHowToApplyByProgram,
    getComplexProgramsByCategories,
    getSimplePrograms,
    getSimpleProgramById,
    getSimpleProgramsByCategories,
    postHomeFirstApplication,
    getButtons
}
